import React, { CSSProperties, RefObject } from 'react';

export interface IFormMultiple {
  name: string;
  value: any;
}

interface FormProps<T = any> {
  children: React.ReactNode;
  onFinish: (formData: T) => void;
  className?: string;
  refObject?: RefObject<HTMLFormElement>;
  style?: CSSProperties;
}

const Form = ({ children, onFinish, className, refObject, style }: FormProps) => {

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget).entries();
    const formValues = Object.fromEntries(formData);

    onFinish(formValues);
  };

  return (<form ref={refObject} className={className} style={style} onSubmit={handleSubmit}>{children}</form>);
};

export default Form;