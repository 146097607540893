const loggedUserTitle = (roleId: number, loggedUsername?: string) => {
    const userTitles = [
        {
            role_id: 1,
            text: "Administrador Senne",
        },
        {
            role_id: 2,
            text: loggedUsername,
        },
        {
            role_id: 3,
            text: "Paciente",
        },
        {
            role_id: 4,
            text: "Médico",
        },
        {
            role_id: 5,
            text: "Atendimento",
        },
    ];

    return userTitles.find(title => title.role_id === roleId)?.text;
}

export default loggedUserTitle;