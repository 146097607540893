import React, { useState } from "react";
import api from "../services/api";
import { toast } from "react-toastify";
import { useUsers } from "../hooks";

export const HospitalsContext = React.createContext({});

export const HospitalsProvider = ({ children }) => {
  const { getUsers } = useUsers();
  const [hospitalsList, setHospitalsList] = useState([]);

  async function getHospitalsList() {
    
    try {
      const { data } = await api.get("/hospitals/list");

      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return error;
    }
      
  }

  async function getUserHospitalList() {
    try {
      const { data } = await api.get("/user/hospitals/list");
      return data;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return error;
    }
  }

  async function getHospitals() {
    const response = await api.get("/list/procedencia");
    setHospitalsList(response.data.Hospitals);

    return response.data;
  }
  async function createUserByHospital(data) {
    try {
      const response = await api.post("/hospital/user/store", data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
      await getUsers();
      return response;
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return error;
    }
  }
  async function listHospital(grupo_id) {
    try {
      const response = await api.get(`list/hospitals/group/${grupo_id}`);

      return response?.data;
    } catch (error) {
      return error;
    }
  }
  const EditImageHospital = async (data) => {
    const response = await api.post(`/upload/hospital/image`, data);
    // toast.success("Imagem Editada com sucesso.");

    return response;
  };

  async function ShowHospitalsForId(grupo_id) {
    try {
      const response = await api.get(`/show/hospitals/${grupo_id}`);

      return response?.data;
    } catch (error) {
      return error;
    }
  }

  async function EditHospital(hospital_id, data) {
    try {
      const response = await api.put(`/hospital/${hospital_id}`, data);
      if (response?.data?.message) {
        toast.success(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      return error;
    }
  }
  async function hospitalList(grupo_id) {
    try {
      const response = await api.get(`hospital/user/list/${grupo_id}`);

      return response?.data;
    } catch (error) {
      return error;
    }
  }

  async function getHospitalNotification(uuid) {
    try {
      const response = await api.get(`list/notifications/${uuid}`);

      return response?.data?.message;
    } catch (error) {
      return error;
    }
  }

  return (
    <HospitalsContext.Provider
      value={{
        hospitalsList,
        getUserHospitalList,
        getHospitalsList,
        getHospitals,
        createUserByHospital,
        listHospital,
        hospitalList,
        ShowHospitalsForId,
        EditHospital,
        EditImageHospital,
        getHospitalNotification
      }}
    >
      {children}
    </HospitalsContext.Provider>
  );
};
