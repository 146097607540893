import { useParams } from "react-router-dom";
import Aside from "../../components/Aside";
import { useState } from "react";

export const Report = () => {
	const params = useParams();
	const reportId = parseInt(params.report_id as string);

	const fetchTest = async () => {
		const content = await fetch('https://api.senneliquor.com.br/api/apoio/valida/f2Dd1856A10bB6', { method: 'GET' });
		console.log(content);
	}

	return (
		<>
			teste - report - {reportId}

			<button onClick={fetchTest}>test fetch</button>
		</>
	);
}