import { Flex, Link } from "@chakra-ui/react";
import { useState } from "react";

const listCores = [
  {
    Text: "Em análise",
    cor: "#0DCAF0",
  },
  {
    Text: "Resultado Liberado",
    cor: "#B9BD5C",
  },
  {
    Text: "Resultado Alterado",
    cor: "#EC6726",
  },
  {
    Text: "Aguardando Execução",
    cor: "#EBB71C",
  },
  {
    Text: "Necessidade de Material",
    cor: "#AB3289",
  },
  {
    Text: "Amostra Insuficiente",
    cor: "#7C8CA6",
  },
  {
    Text: "Cancelado",
    cor: "#DC3545",
  },
  {
    Text: "Cancelado por Amostra",
    cor: "#7D1111",
  },
];

export default function PaletaCores() {
  const [number, setNumber] = useState(3);

  function seeMore() {
    if (number < listCores.length) {
      setNumber(listCores.length + 1);
    } else {
      setNumber(3);
    }
  }

  return (
    <>
      <Flex gap="10px" flexDirection="column">
        <Flex
          gap="20px"
          alignContent="center"
          alignItems="center"
          wrap="wrap"
          w="100%"
          height='fit-content'
        >
          {listCores?.map((item, index) => {
            if (number > index) {
              return (
                <Flex
                  key={index}
                  gap="10px"
                  alignContent="center"
                  alignItems="center"
                >
                  <Flex
                    borderRadius="50%"
                    w="16px"
                    h="16px"
                    backgroundColor={item.cor}
                  ></Flex>
                  {item.Text}
                </Flex>
              );
            } else return "";
          })}
        </Flex>
        <Link
          w="70px"
          color="blue"
          borderBottom="1px solid blue"
          textDecoration="blue"
          onClick={seeMore}
        >
          Ver Mais{" "}
        </Link>
      </Flex>
    </>
  );
}
