import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/react";
import { useAuth } from "../../../hooks";
import { PrimaryButton, GoBackButton } from "../../../components/Buttons";

import TemplateLogin from "../../../components/TemplateLogin";
import PasswordInput from "../../../components/TextInput";
import CheckboxInput from "../../../components/CheckboxInput";
import TextInput from "../../../components/TextInput";
import Loading from "../../../components/Loading";
import styled from "styled-components";
import Card from "../../../components/Card";
import Hospitalar from "../../../images/labOrange.svg";
import Form from "../../../components/Form";

export default function HospitalarLogin() {
  const [loading, setLoading] = useState(false);

  const { signIn } = useAuth();
  const navigate = useNavigate();

  async function handleSubmit(values) {
    try {
      setLoading(true);

      const response = await signIn(values, 2);

      if (response.data.type === "validate_cellphone") {
        let { cellphone, expire_date } = response.data;

        navigate("/validar/numero", {
          state: {
            cellphone: cellphone,
            expire_date: expire_date,
          },
        });
        return;
      }

      navigate("/dashboard");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <TemplateLogin image="">
      {loading && <Loading />}
      <Content>
        <Card>
          <Flex
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            gap="37px"
            mb="71px"
          >
            <img src={Hospitalar} alt="" />
            <h1>Hospitalar</h1>
          </Flex>
          <Form onFinish={handleSubmit}>
            <TextInput
              defaultValue=""
              labelDescription="Login com seu e-mail cadastrado"
              InputPlaceholder="Email"
              name="email"
              type="email"
              id="email"
              required
            />
            <PasswordInput
              labelDescription="Senha"
              InputPlaceholder="Senha"
              name="password"
              type="password"
              id="password"
              autoFocus
              password
              required
            />

            <Flex alignItems="center" justifyContent="space-between">
              <CheckboxInput labelDescription="Lembrar Senha"></CheckboxInput>
              <Link to="/nova-senha" target="_blank">
                <Text
                  color="var( --orange-senne-secundary)"
                  textDecoration="underline"
                >
                  Esqueci a Senha
                </Text>
              </Link>
            </Flex>
            <PrimaryButton type="submit">Entrar</PrimaryButton>
          </Form>
          <Flex width="100%" justifyContent="center">
            <Link to="/login">
              <GoBackButton />
            </Link>
          </Flex>
        </Card>
      </Content>
    </TemplateLogin>
  );
}
const Content = styled.div`
  padding: 72px 80px;

  h1 {
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
  }
  p {
    line-height: 24px;
    font-size: 16px;
    margin: 16px 0;
  }
  > div {
    margin: 24px 0 0;
  }
  a {
    text-decoration: none;
  }
  @media (max-width: 414px) {
    padding: 15px;
    h1 {
      font-size: 1.5rem;
    }
    div {
      margin: 12px 0 20px 0;
    }
    input {
      margin-bottom: 10px;
    }
  }
`;
