import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Text,
  Input,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";

import { BreadCrumbs } from "../../components/Breadcrumb2";
import { GreenButton } from "../../components/Buttons/index";
import { Pagination } from "../../components/Pagination";
import InternalHeader from "../../components/InternalHeader";
import Loading from "../../components/Loading";
import SideBar from "../../components/SideBar";

import { usePartners } from "../../hooks";

import Search from "../../images/search.svg";

const Partners = () => {
  const { getPartners, approvePartner } = usePartners();
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);

  const itemsPerPage = 5;
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const approveUser = async (id, data) => {
    try {
      setLoading(true);
      const data = {
        id: id,
      };
      await approvePartner(id, data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      getPartnersList();
    }
  };

  const getPartnersList = async () => {
    try {
      const params = {
        page: currentPage ? currentPage + 1 : 1,
        per_page: itemsPerPage,
        name: partners,
      };
      const response = await getPartners();

      setPartners(response?.Partners);
      setPageCount(response?.Partners?.last_page);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPartnersList();
  }, []);

  return (
    <Flex width="100%" maxHeight="100vh" backgroundColor="#F7F8FC">
      {loading && <Loading />}
      <Box p="24px" w="100%">
        <BreadCrumbs title="Gerenciamento de Parceiros" />
        <Box
          mt="44px"
          p="15px 20px"
          border="1px solid #CED4DA"
          borderRadius="10px"
          backgroundColor="#fff"
        >
          <Flex justifyContent="space-between">
            <Flex
              w="477px"
              p="0 12px"
              border="1px solid #CED4DA"
              color="#8F8F8F"
              borderRadius="4px"
              alignContent="center"
              alignItems="center"
              gap="10px"
            >
              <img src={Search} alt="" />
              <Text as="strong">Pesquisar:</Text>
              <Input
                padding="0"
                _focus={{
                  border: "none",
                }}
                onChange={(e) => console.log(e.target.value)}
                border="none"
                placeholder="Procure por email ou nome do paciente"
              />
            </Flex>
          </Flex>

          <Flex
            mt="20px"
            pt="20px"
            borderTop="1px solid var(--orange-senne)"
            fontSize="13px"
            lineHeight="14px"
          >
            <TableContainer
              w="100%"
              borderRadius="8px"
              border="var(--cinza-card) 1px solid"
              maxH="300px"
              overflowY="scroll"
            >
              <Table variant="simple">
                <Thead backgroundColor="var(--cinza-bg)">
                  <Th borderLeft="var(--cinza-card) 1px solid">Solicitante</Th>
                  <Th borderLeft="var(--cinza-card) 1px solid">Empresa</Th>
                  <Th borderLeft="var(--cinza-card) 1px solid">Email</Th>
                  <Th borderLeft="var(--cinza-card) 1px solid">Tipo</Th>
                  <Th borderLeft="var(--cinza-card) 1px solid">Ações</Th>
                </Thead>
                <Tbody>
                  {partners.map((partner, index) => (
                    <Tr
                      key={index}
                      _hover={{
                        backgroundColor: "var(--orange-hover)",
                        cursor: "pointer",
                      }}
                    >
                      <Td p="0 8px" borderLeft="1px solid var(--cinza-card)">
                        {partner?.name}
                      </Td>
                      <Td p="0 8px" borderLeft="1px solid var(--cinza-card)">
                        {partner?.name_company}
                      </Td>

                      <Td p="0 8px" borderLeft="1px solid var(--cinza-card)">
                        {partner?.email}
                      </Td>
                      <Td p="0 8px" borderLeft="1px solid var(--cinza-card)">
                        {partner?.classification}
                      </Td>
                      <Td p="0 8px" borderLeft="1px solid var(--cinza-card)">
                        <Menu>
                          <MenuButton
                            style={{
                              backgroundColor: "transparent",
                              display: "flex",
                              margin: "0 auto",
                            }}
                            as={IconButton}
                            icon={
                              <GreenButton
                                style={{
                                  padding: "0 8px 8px",
                                  width: "40px",
                                }}
                              >
                                ...
                              </GreenButton>
                            }
                          />
                          <MenuList>
                            <Link
                              fontSize="12px"
                              to={""}
                              onClick={() => approveUser(partner?.id)}
                            >
                              <MenuItem fontSize="12px">
                                <Text>Aprovar Usuário</Text>
                              </MenuItem>
                            </Link>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
          <Flex mt="18px" justifyContent="end">
            <Pagination
              pageCount={pageCount}
              changePage={setCurrentPage}
              pageSelected={currentPage}
            />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default Partners;
