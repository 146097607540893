function UserIcon() {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="path-1-inside-1_232_195265" fill="white">
				<path fillRule="evenodd" clipRule="evenodd" d="M19.8163 6.15552C23.7479 6.15552 26.9217 9.32926 26.9217 13.2609C26.9217 17.1926 23.7479 20.3663 19.8163 20.3663C15.8846 20.3663 12.7109 17.1926 12.7109 13.2609C12.7109 9.32926 15.8846 6.15552 19.8163 6.15552ZM19.8163 39.7877C14.1006 39.7877 8.76497 36.9243 5.60547 32.1612C5.67652 27.448 15.0793 24.8664 19.8163 24.8664C24.5295 24.8664 33.956 27.448 34.027 32.1612C30.8675 36.9243 25.5319 39.7877 19.8163 39.7877Z" />
			</mask>
			<path fillRule="evenodd" clipRule="evenodd" d="M19.8163 6.15552C23.7479 6.15552 26.9217 9.32926 26.9217 13.2609C26.9217 17.1926 23.7479 20.3663 19.8163 20.3663C15.8846 20.3663 12.7109 17.1926 12.7109 13.2609C12.7109 9.32926 15.8846 6.15552 19.8163 6.15552ZM19.8163 39.7877C14.1006 39.7877 8.76497 36.9243 5.60547 32.1612C5.67652 27.448 15.0793 24.8664 19.8163 24.8664C24.5295 24.8664 33.956 27.448 34.027 32.1612C30.8675 36.9243 25.5319 39.7877 19.8163 39.7877Z" fill="#B9BD5C" />
			<path d="M19.8163 39.7877L19.8163 38.2177H19.8162L19.8163 39.7877ZM5.60547 32.1612L4.03565 32.1376L4.02832 32.6238L4.29714 33.0291L5.60547 32.1612ZM34.027 32.1612L35.3354 33.0291L35.6042 32.6238L35.5969 32.1376L34.027 32.1612ZM19.8163 7.72552C22.8808 7.72552 25.3517 10.1963 25.3517 13.2609H28.4917C28.4917 8.46217 24.615 4.58552 19.8163 4.58552V7.72552ZM25.3517 13.2609C25.3517 16.3255 22.8808 18.7963 19.8163 18.7963V21.9363C24.615 21.9363 28.4917 18.0596 28.4917 13.2609H25.3517ZM19.8163 18.7963C16.7517 18.7963 14.2809 16.3255 14.2809 13.2609H11.1409C11.1409 18.0596 15.0175 21.9363 19.8163 21.9363V18.7963ZM14.2809 13.2609C14.2809 10.1963 16.7517 7.72552 19.8163 7.72552V4.58552C15.0175 4.58552 11.1409 8.46217 11.1409 13.2609H14.2809ZM19.8162 38.2177C14.6268 38.2177 9.78241 35.6179 6.91379 31.2934L4.29714 33.0291C7.74753 38.2306 13.5744 41.3577 19.8163 41.3577L19.8162 38.2177ZM7.17529 32.1849C7.18455 31.5705 7.49073 30.9026 8.26332 30.1786C9.04253 29.4483 10.1873 28.7718 11.567 28.1947C14.3327 27.0379 17.6621 26.4364 19.8163 26.4364V23.2964C17.2334 23.2964 13.493 23.9856 10.3554 25.2979C8.78333 25.9554 7.26801 26.808 6.11618 27.8874C4.95773 28.9731 4.06191 30.3953 4.03565 32.1376L7.17529 32.1849ZM19.8163 26.4364C21.958 26.4364 25.2877 27.0378 28.0572 28.195C29.4387 28.7722 30.5858 29.449 31.367 30.1796C32.1417 30.9042 32.448 31.5719 32.4572 32.1849L35.5969 32.1376C35.5706 30.3939 34.6719 28.9714 33.5119 27.8864C32.3583 26.8074 30.8409 25.955 29.2678 25.2977C26.1279 23.9858 22.3877 23.2964 19.8163 23.2964V26.4364ZM32.7187 31.2934C29.8501 35.6179 25.0057 38.2177 19.8163 38.2177L19.8162 41.3577C26.0582 41.3577 31.885 38.2306 35.3354 33.0291L32.7187 31.2934Z" fill="#B9BD5C" mask="url(#path-1-inside-1_232_195265)" />
			<circle cx="19.6188" cy="20.1689" r="18.8338" stroke="#B9BD5C" strokeWidth="1.57" />
		</svg>

	);
}

export default UserIcon;
