import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AsideItem } from "./types";
import { useAuth } from "../../hooks";

import classNames from "classnames";
import LogoIcon from "../../assets/icons/LogoIcon";
import HouseIcon from "../../assets/icons/HouseIcon";
import CalendarSidebarIcon from "../../assets/icons/CalenarSidebarIcon";
import UserSidebarIcon from "../../assets/icons/UserSidebarIcon";
import FolderIcon from "../../assets/icons/FolderIcon";
import ExitIcon from "../../assets/icons/ExitIcon";
import MenuItem from "./MenuItem";
import UserIcon from "../../assets/icons/UserIcon";
import loggedUserTitle from "./constants";

import './style.scss';

const getAsideItems = (authLogout: () => void): AsideItem[] => {
    const token = localStorage.getItem("Auth_senne");
    const hospitalUuid = localStorage.getItem("hospital");
    const userId = localStorage.getItem("User_id_senne");

    return [
        {
            content: 'Início',
            icon: <HouseIcon />,
            href: '/dashboard'
        },
        {
            content: 'Agendamento',
            href: `${process.env.REACT_APP_SCHEDULE_URL}/login/sso?token=${token}&hospitalUuid=${hospitalUuid}&userId=${userId}`,
            icon: <CalendarSidebarIcon />
        },
        {
            content: 'Usuários',
            href: '/gerenciamento-senne',
            icon: <UserSidebarIcon />
        },
        {
            content: 'Relatório',
            href: '#report',
            icon: <FolderIcon />,
            classname: '--from_awesome',
            searchable: true,
            dropdown: [
                {
                    content: 'Relatório X',
                    href: '/relatorio/1'
                },
                {
                    content: 'Relatório Y',
                    href: '/relatorio/2'
                }
            ]
        },
        {
            classname: '--logout_bottom',
            content: 'Sair',
            href: '#exit',
            icon: <ExitIcon />,
            onClick: () => authLogout()
        }
    ];
}

const Aside = ({ children }: { children: ReactNode }) => {
    const auth = useAuth() as { user: { role_id: number; hash: string; name: string; hospitals: { name: string; }[]; }, logout: () => void };
    const location = useLocation();

    const asideRef = useRef<HTMLElement>(null);
    const toggleAsideButtonRef = useRef<HTMLDivElement>(null);

    const [asideIsOpen, setAsideIsOpen] = useState(true);

    const menuItems = useMemo(() => <MenuItem asideItems={getAsideItems(auth.logout)} pathname={location.pathname} />, [location.pathname]);
    const footer = useMemo(() => <Footer />, []);

    const userTitle = loggedUserTitle(auth.user.role_id, auth.user?.hospitals?.[0]?.name);

    useEffect(() => {
        const handleAsideClickOutside = (e: MouseEvent) => {
            if (
                window.innerWidth <= 768 &&
                asideRef.current &&
                toggleAsideButtonRef.current &&
                !asideRef.current.contains(e.target as Node) &&
                !toggleAsideButtonRef.current.contains(e.target as Node)
            ) {
                setAsideIsOpen(false);
            }
        };

        window.addEventListener('click', handleAsideClickOutside);

        return () => window.removeEventListener('click', handleAsideClickOutside);
    }, []);

    useEffect(() => {
        if (window.innerWidth <= 768) {
            setAsideIsOpen(false);
        }
    }, [location.pathname]);

    return (
        <>
            <header className="header">
                <div className="header_logo">
                    <div className="icon">
                        <LogoIcon />
                    </div>
                    <div
                        ref={toggleAsideButtonRef}
                        className={classNames('header_aside_toggle', { '--aside_is_open': asideIsOpen })}
                        onClick={() => setAsideIsOpen(prev => !prev)}
                    />
                </div>
                <div className="header_user">
                    <div className="header_user_content">
                        <div className="header_user_content_hospital">
                            {userTitle}
                        </div>
                        <div className="header_user_content_name">
                            {auth.user.name}
                        </div>
                    </div>
                    <div className="header_user_image">
                        <UserIcon />
                    </div>
                </div>
            </header>
            <aside
                ref={asideRef}
                className={classNames('aside', { '--aside_is_open': asideIsOpen })}
                children={menuItems}
            />
            <div className={classNames('aside_content', { '--aside_is_open': asideIsOpen })} children={children} />
            <footer className="footer" children={footer} />
        </>
    );
}

export default Aside;

const renderMenuItem = (asideItems: AsideItem[], searchable?: boolean) => {
    return (
        <ul>
            {searchable && (
                <li>
                    <input type='text' placeholder="Buscar" />
                </li>
            )}
            {asideItems.map(asideItem => {
                return (
                    <li className={classNames(asideItem.classname, { '--is_dropdown': !!asideItem.dropdown?.length })}>
                        <Link to={asideItem.href}>
                            {asideItem.icon && <i>{asideItem.icon}</i>}
                            <span>{asideItem.content}</span>
                        </Link>
                        {asideItem.dropdown && renderMenuItem(asideItem.dropdown, asideItem.searchable)}
                    </li>
                )
            })}
        </ul>
    );
}

const Footer = () => {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => setDate(new Date()), 60000);
        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <div className="footer_datetime">
                {new Intl.DateTimeFormat(
                    'pt-BR',
                    {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric'
                    }
                ).format(date)} - {new Intl.DateTimeFormat(
                    'pt-BR',
                    {
                        hour: '2-digit',
                        minute: '2-digit'
                    }
                ).format(date)}
            </div>
        </>
    );
}