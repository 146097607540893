import React from "react";

import { 
  Text,
  Modal, 
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  Button,
  ModalHeader,
  Flex,
  ModalFooter,

} from "@chakra-ui/react";

import SelectHospitalPopup from "../SelectHospitalPopup";

const ModalSelectHospital = ({ handleOnChangeHospital, hospitalList, isOpen, onClose }) => {
  // const { onClose } = useDisclosure();

  return (    
      <>
        <Modal onClose={onClose} isOpen={isOpen} size="xl" isCentered >
          <ModalOverlay>
            <ModalContent>
              <ModalHeader style={{textAlign: "center", paddingTop: 50 }}>
                <Text color={"#990507"} fontSize={18}>Selecione o hospital que deseja ter acesso</Text>
              </ModalHeader>
              <ModalBody style={{textAlign: "center"}}>
                
                <Flex 
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <SelectHospitalPopup
                    labelDescription=""
                    name="hospital"
                    id="idHospital"
                    titleOption="Escolha o hospital"
                    onChange={(e) => handleOnChangeHospital(e.target.value)}
                    options={hospitalList}
                  />

 
                </Flex>
 
              </ModalBody>
              <ModalFooter>
                {/* <Button
                  
                  border="1px solid #CED4DA"
          
                  backgroundColor="var(--orange-senne)"
                  _hover={{ opacity: 0.7 }}
                  p="10px 10px"
                  color="white"
                  onClick={onClose}
                  // eslint-disable-next-line no-undef
                  
                  
                >
                  Fechar
                </Button> */}
              </ModalFooter>
            </ModalContent>
          </ModalOverlay>

        </Modal>
      </>


  );
};

export default ModalSelectHospital;
