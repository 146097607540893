import React, { useState, useEffect } from "react";
import { registerLocale } from "react-datepicker";
import {
  Flex,
  Box,
  extendTheme,
  useMediaQuery,

} from "@chakra-ui/react";

// import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'

import GridAttendance from "../../components/GridAttendance";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

import SideBar from "../../components/SideBar";
import InternalHeader from "../../components/InternalHeader";
import { useAuth } from "../../hooks";

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

extendTheme({ breakpoints });

function Exams() {
  // const navigate = useNavigate();
  registerLocale("ptBR", ptBR);

  const [isMobile] = useMediaQuery("(max-width: 820px)") 

  const { user } = useAuth();

  // const [search, setSearch] = useState("");
  // const [showHospital, setShowHospital] = useState(false);

  const itemsPerPage = 7;
  // const [pageCount, setPageCount] = useState(0);
  // const [currentPage, setCurrentPage] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [widthScreen, setWidthScreen] = useState(0);

  // var today = new Date();

  const [uuidUser, setUUIDUser] = useState();

  const [hospitalName, setHospitalName] = useState("");
  
  function getDate() {
    if (user?.role_id === 2) {
      if(user?.hospitals.length === 1){
        setUUIDUser(user?.hospitals[0].uuid);
      }      
    }    
    // if (user?.role_id === 1) {
    //   setUUIDUser(uuidHopistal);
    // }
    if (user?.role_id === 3) {
      setUUIDUser(user?.hash);
    }
    if (user?.role_id === 4) {
      setUUIDUser(user?.hash);
    }
  }

  // const handleOnChangeHospital = (uuidChanged) => {
  //   setUUIDUser(uuidChanged);
  // };

  // useEffect(() => {
  //   if(!uuidUser && user.role_id === 4){
  //     setUUIDUser(user.hash);
  //     return;
  //   }

  //   if(!uuidUser && user.role_id !== 3 && user.hospitals.length > 0){
  //     setUUIDUser(user.hospitals[0].uuid );
  //   } 

  //   if(user.role_id === 1 || user.hospitals.length > 0){
  //     // setUUIDUser(user.hospitals[0].uuid);
  //     setShowHospital(true);
  //   }

    
  // }, []);

  useEffect(() => {

    getDate();

    if ((uuidUser === undefined  || uuidUser == null) && localStorage.getItem("hospital")) {

      // console.log("uuidUser local", localStorage.getItem("hospital") > 1);
      localStorage.getItem("hospital").length > 1 && setUUIDUser(localStorage.getItem("hospital"));
      localStorage.getItem("hospitalName") ?? setHospitalName(localStorage.getItem("hospitalName"));
    }    

    
    if (user.role_id === 3) {
      setHospitalName("Paciente")
    }
    if (user.role_id === 4) {
      setHospitalName("Médico")

    }
  }, []); 


  return (
    <Flex backgroundColor="#F7F8FC" minH="100vh" minW="100vw">

      {
        !isMobile && (

          <Flex width="220px" id="sidebar" className="sidebar">
            <SideBar />
          </Flex>

        )
      }
   
      <Box 
        p={ isMobile ? "3em 1em 3em 1em" : "3em 3em 3em 3em" }
      
        w="100%"
      >
        
        {/* <InternalHeader 
          onChangeHospital={handleOnChangeHospital} 
          setUUIDUser={setUUIDUser} 
          uuidUser={uuidUser} 
          showHospital={showHospital}
          isMobile={isMobile}
        />
         */}

          <InternalHeader 
            user={user}
            // showHospital={true}
            uuidUser={uuidUser ? uuidUser : localStorage.getItem("hospital")}
            hospitalName={hospitalName ? hospitalName : localStorage.getItem("hospitalName")}
          />
        <Flex  mb="12">
          
        </Flex>
        
        <GridAttendance 
          pageSize={itemsPerPage}
          uuidSelected={uuidUser}
        />

      </Box>
    </Flex>
  );
}

export default Exams;
