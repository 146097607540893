import axios from "axios";
import { toast } from "react-toastify";

const api_public = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
  timeout: 1000 * 30,
});

api_public.interceptors.response.use(
  (response) => Promise.resolve(response),

  (error) => {

    if (!error.response) {
      toast.error("Falha na conexão com o servidor");
      return Promise.reject(error);
    }

    if (error.response?.status >= 400 && error.response.status <= 500) {
      // window.location.pathname = "/";
      toast.error(error.response.data);
    }

    if (error.response?.status >= 500 || error?.response?.status === 0) {
      toast.error('Falha ao processar a solicitação');
    }

    return Promise.reject(error);
  },
);


export default api_public;
