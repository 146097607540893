import React, { useState } from "react";
import { Link } from "react-router-dom";
import { 
  Box,
  Flex, 
  Text,  
  TableContainer, 
  Table,
  Thead, 
  Tr, 
  Td, 
  Th, 
  Tbody, 
  Tooltip ,
  Modal, 
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  useDisclosure,  

} from "@chakra-ui/react";
import { useAuth } from "../../hooks";

import { formatDate } from "../../helpers";

import { Pagination } from "../../components/Pagination";



const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

const GridAuthorization = ({ 
  showAuthorization, 
  pageCountAuthorization,
  setCurrentPageAuthorization,
  currentPageAuthorization

}) => {
  const { user } = useAuth();

  const [widthScreen, setWidthScreen] = useState(0);


  const handleButton = () => {
    onOpen()
  }
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (

    <>

    <Box
      m="18px 0"
      p="10px 20px"
      border="1px solid #CED4DA"
      borderRadius="10px"
      backgroundColor="var(--cinza-bg)"
    >

      {/* Cabeçalho */}
      <>
        <Text 
              color="#F3953F" 
              fontSize={{ base: "16px", md: "26px" }} as="strong"
              fontWeight={800}
        >
          Exames Aguardando Autorização
        </Text>

      </>
      
      <Flex
        fontSize="13px"
        // overflowX="scroll"
        // scrollBehavior="none"
        flexDirection="column"
        // border="1px solid #CED4DA"
      >
        <TableContainer
          border="1px solid #CED4DA"
          borderRadius="6px"
          w="100%"
          // h="494px"
          // maxH="516px"


          maxW={`calc(100% - ${widthScreen})`}
          
          
          // overflowY="scroll"
        >
          {showAuthorization?.length > 0 ? (
            <Table
              variant="simple"
              border="var(--cinza-card) 1px solid"
              borderRadius="50px"
            >
              <Thead>
                <Tr borderRadius="8px">
                  {/* <Th borderRadius="8px" maxW="50px"></Th> */}
                  <Th maxW="100px">Atendimento</Th>
                  <Th maxW="180px">
                    Paciente
                  </Th>
                  <Th maxW="120px">Status</Th>
                  <Th maxW="180px">
                    Exame
                  </Th>
                  {/* <Th maxW="190px">Grupo/Unidade</Th> */}
                  <Th maxW="110px">Data Solicitação</Th>
                </Tr>
              </Thead>
              <Tbody>
                {showAuthorization?.map((auth, index) => (
                  <Tr
                    onClick={() => handleButton()}
                    cursor="pointer"
                    key={auth.nr_sequencia}
                    _hover={{ backgroundColor: "#F3953F15" }}
                  >
                    {/* <Td maxW="50px" key="index">
                      <Checkbox />
                    </Td> */}
                    <Td
                      maxW="100px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      <Flex justifyContent="space-between">
                        <Tooltip
                          hasArrow
                          label={auth?.nr_atendimento}
                          bg="gray.300"
                          color="black"
                        >
                          <Text as="strong">{auth?.nr_atendimento}</Text>
                        </Tooltip>

                      </Flex>
                    </Td>
 
                    <Td
                      maxW="160px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      <Tooltip
                        hasArrow
                        label={auth?.nm_paciente}
                        bg="gray.300"
                        color="black"
                      >
                        <Flex flexDirection="column">
                          <Text>{auth?.nm_paciente}</Text>
                        </Flex>
                      </Tooltip>
                    </Td>
                    <Td>{auth?.ds_convenio}</Td>
                    <Td
                      maxW="180px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      <Tooltip
                        hasArrow
                        label={auth?.ds_procedimento}
                        bg="gray.300"
                        color="black"
                      >
                        <Flex flexDirection="column">
                          <Text>{auth?.ds_procedimento}</Text>
                        </Flex>
                      </Tooltip>
                    </Td>
                    {/* <Td
                      maxW="200px"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      <Tooltip
                        hasArrow
                        label={exam?.nomeprocedencia}
                        bg="gray.300"
                        color="black"
                      >
                        <Text as="strong">{exam?.nomeprocedencia}</Text>
                      </Tooltip>
                    </Td> */}
                    <Td maxW="80px">{formatDate(auth?.dt_autorizacao)}</Td>

                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Text p="80px 20px">
              Não existem autorizações pendentes.
            </Text>
          )}
        </TableContainer>
      </Flex>
      {/* Button imprimir laudos */}
      <Flex p="20px 20px 0 0" justifyContent="space-between">
        <Pagination
          pageCount={pageCountAuthorization}
          changePage={setCurrentPageAuthorization}
          pageSelected={currentPageAuthorization}
        />
      </Flex>
      
        <>
          <Modal onClose={onClose} isOpen={isOpen} size="xl">
            <ModalOverlay>
              <ModalContent>
                <ModalHeader style={{textAlign: "center"}}>
                  <Text>Exame requer atenção</Text>
                  <ModalCloseButton />
                </ModalHeader>
                <ModalBody style={{textAlign: "center"}}>
                  <Text style={{ base: "16px", marginBottom: "15px", alignContent: "center"}}> Para maiores informações, envie um e-mail para </Text>
                  <strong><Text style={{marginBottom: "15px"}}>financeiro@senneliquor.com.br</Text></strong>
                  
                  <Text style={{marginBottom: "15px"}}>Em casos de urgência, entre em contato pelo telefone</Text>
                  <strong><Text style={{marginBottom: "30px", fontSize: "26px"}}>(11) 3286-8989</Text></strong>

                </ModalBody>
              </ModalContent>
            </ModalOverlay>

          </Modal>
        </>

    </Box>
    </>

  );
};

export default GridAuthorization;
