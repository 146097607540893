// import styles from "./styles.module.scss";
import React from "react";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Link, useLocation } from "react-router-dom";
import { Flex, Text, Box } from "@chakra-ui/react";
import { authenticatedRoutes } from "../../services/constants";
import styles from "./styles.module.scss";


export function BreadCrumbs({ title, isMobile }) {
  const breadcrumbs = useBreadcrumbs(authenticatedRoutes);
  const location = useLocation();
  return (
    <Box>
      <div className={styles.container}>
        {breadcrumbs.map(({ match, key }, index) => {
          // setTitle("oi");

          return (
            <div className={styles.item} key={index}>
              {
                // !!breadcrumbs[index - 1]?.match?.route?.breadcrumb &&
                breadcrumbs[index]?.match?.route?.breadcrumb ? (
                  <div className={styles.divider} />
                ) : (
                  ""
                  // <div className={styles.divider} />
                )
              }
              {/* <div
                className={`${styles.breadOption}
                ${
                  index === breadcrumbs.length - 1
                    ? styles.breadActive
                    : styles.breadPrevious
                }`}
              >
                {location.pathname === "/" ? (
                  <Link to={breadcrumbs[0]?.match?.pathname}>
                    <Flex align="center" gap="4">
                      <Text fontSize="16px">
                        {breadcrumbs[0].match?.route?.breadcrumb}
                      </Text>
                    </Flex>
                  </Link>
                ) : (
                  index > 0 && (
                    <Link to={match.pathname}>
                      <Flex align="center" gap="2">
                        <Text fontSize="16px">{match?.route?.breadcrumb}</Text>
                      </Flex>
                    </Link>
                  )
                )}
              </div> */}
            </div>
          );
        })}
      </div>
      <Text fontSize="1.825rem" color="#F3953F" fontWeight="800">
        {title}
      </Text>
    </Box>
  );
}
